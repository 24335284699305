<template>
  <div class="container">
    <div class="content">
      <!-- <div>
        <div class="balance">{{donateDividends.cumulative}}(LP)</div>
        <div class="data">{{balance}} LP</div>
      </div> -->
      <div>
        <div class="balance">Metabill LP</div>
        <div class="data">{{balance}}</div>
      </div>
      <div style="margin-top:35px">
          <van-button class="btn" @click="processDonate" :loading="balanceLoading" :disabled="!+balance">{{donateDividends.initiate}}</van-button>
      </div>
      <!-- <div class="item">
        <div class="left">
          <div class="title">{{donateDividends.balance}}</div>
          <div class="data">{{balance}} LP</div>
        </div>
        <div class="right">
          <van-button class="btn" @click="go('/activity')">{{donateDividends.community}}</van-button>
          <van-button class="btn" @click="processDonate" :loading="balanceLoading">{{donateDividends.initiate}}</van-button>
        </div>
      </div> -->
    </div>
    <!-- 众筹lp池 -->
    <div class="content">
      <div>
        <div class="balance">Metacrowd LP</div>
        <div class="data">{{balance1}}</div>
      </div>
      <div style="margin-top:35px">
          <van-button class="btn"  @click="processDonate1" :loading="balance1Loading" :disabled="!+balance1">{{donateDividends.initiate}}</van-button>
      </div>
    </div>
    <!-- mai分发池 -->
    <div class="content">
      <div>
        <div class="balance">MAI</div>
        <div class="data">{{$gbUtils.formatEightBalance(balanceMai)}}</div>
      </div>
      <div style="margin-top:35px">
          <van-button class="btn"  @click="processDonateMai" :loading="balanceMaiLoading" :disabled="!+balanceMai">{{donateDividends.initiate}}</van-button>
      </div>
    </div>
     <!-- mcoin分发池 -->
     <div class="content">
      <div>
        <div class="balance">MCOIN</div>
        <div class="data">{{$gbUtils.formatEightBalance(balanceMcoin)}}</div>
      </div>
      <div style="margin-top:35px">
          <van-button class="btn"  @click="processDonateMcoin" :loading="balanceMcoinLoading" :disabled="!+balanceMcoin">{{donateDividends.initiate}}</van-button>
      </div>
    </div>
    <div class="list">
      <div class="list-item title">
        <div class="left">{{donateDividends.address}}</div>
        <div class="right">{{donateDividends.expected}}</div>
      </div>
      <div class="line"></div>
      <div class="list-item" v-for="(item, index) in dividendAddress" :key="index">
        <div class="left">{{ item.slice(0, 4) }}...{{ item.slice(-4) }}</div>
        <div class="right">{{(+balance + +balance1) / dividendAddress.length}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import lighting from '@/contracts/lighting.js'
import donation from '@/utils/donation.js'
import contractCrowd from '../contracts/contractCrowd'
import contractMaiDis from '../contracts/contractMaiDis'
import contractDonateProcessor from '../contracts/contractDonateProcessor'
import contractToken from '../contracts/contractToken'
import config from '@/config/app.config.js' // 引入配置文件

export default {
  name: 'DonateDividends',
  data () {
    return {
      link: 'http://uqtpnquhg.fo/rpj',
      balance: 0,
      dividendAddress: [],
      balanceLoading: false,
      balance1: 0,
      balance1Loading: false,
      balanceMai: 0,
      balanceMaiLoading: false,
      mcoinDonateAddress: '',
      mocinSymbol: '-',
      balanceMcoin: 0,
      balanceMcoinLoading: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    donateDividends () {
      return this.$t('donateDividends')
    },
    ...mapState(['myAcount'])
  },
  methods: {
    async init () {
      this.getDonateBalance()
      this.getCrowdBalance()
      this.queryDonatedList()
      this.getMaiBalance()
      // this.getMcoinDonateAddress()
      this.getMcoinBalance()
    },
    // 路由跳转
    go (url) {
      this.$gbUtils.handleToPage(url)
    },
    // 获取账户
    async getDonateBalance () {
      const resp = await lighting.donateBalance()
      if (resp.success) {
        this.balance = this.$gbUtils.formatTwoBalance(this.$web3.fromWei(resp.result.toString())) || 0
      }
    },
    // 获取分红地址
    async queryDonatedList () {
      const resp = await donation.queryDonatedList()
      if (resp.success) {
        this.dividendAddress = resp.result
      }
    },
    // 发起分红
    async processDonate () {
      this.balanceLoading = true
      const resp = await lighting.processDonate()
      this.balanceLoading = false
      if (!resp.success) {
        return this.$toast('fail')
      }
      this.balance = 0
      this.$toast('success')
    },
    // 获取crowd余额
    async getCrowdBalance () {
      const resp = await contractCrowd.donateAmount()
      if (!resp.success) return
      this.balance1 = resp.result
    },
    // 发起分红
    async processDonate1 () {
      this.balance1Loading = true
      const resp = await contractCrowd.processDonate()
      this.balance1Loading = false
      if (!resp.success) {
        return this.$toast('fail')
      }
      this.balance1 = 0
      this.$toast('success')
    },
    // 获取mai余额
    async getMaiBalance () {
      const resp = await contractMaiDis.donateBalance()
      if (!resp.success) return
      this.balanceMai = resp.result
    },
    // mai发起分红
    async processDonateMai () {
      this.balanceMaiLoading = true
      const resp = await contractMaiDis.processDonate()
      this.balanceMaiLoading = false

      if (!resp.success) {
        return this.$toast('fail')
      }
      this.balanceMai = 0
      this.$toast('success')
    },
    // 获取mcoin捐赠合约地址
    async getMcoinDonateAddress () {
      const resp = await contractDonateProcessor.DONATE()
      if (!resp.success) return
      console.log(resp, 'getMcoinDonateAddress')
      this.mcoinDonateAddress = resp.result
    },
    // 获取mcion余额
    async getMcoinBalance () {
      const resp = await contractToken.balanceOf(config.MCOIN, config.donateProcessor)
      this.balanceMcoin = resp.result || 0
    },
    // mai发起分红
    async processDonateMcoin () {
      this.balanceMcoinLoading = true
      const resp = await contractDonateProcessor.processDonate(config.MCOIN)
      this.balanceMcoinLoading = false
      if (!resp.success) {
        return this.$toast('fail')
      }
      this.balanceMcoin = 0
      this.$toast('success')
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 30px 25px;
  background: #242424;
  min-height: 100vh;
  background: linear-gradient(0deg, #A439CF 0%, #F44F96 50%, #FF776F 100%);

  .content {
    padding: 35px 30px;
    margin-bottom: 24px;
    background: #171917;
    border-radius: 25px;
    .balance{
      font-size: 11px;
      font-weight: 500;
      color: #939394;
      margin-bottom: 13px;
    }
    .data{
      font-size: 23px;
      font-weight: bold;
      color: #FFA242;
    }
    .btn{
      width: 165px;
      height: 40px;
      background: linear-gradient(90deg, #622AFF 0%, #5E12E7 99%);
      border-radius: 20px;
      text-align: center;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
  .list {
    color: #FFFFFF;
    padding: 20px 30px;
    background: #171917;
    border-radius: 25px;
    padding-bottom: 20px;
    .line{
      width: 100%;
      height: 1px;
      background: #FFFFFF;
      opacity: 0.3;
      margin-bottom: 20px;
    }
    .list-item {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }
}
</style>
